const routes = {
  authenticated: {
    home: '/',
    eligibility: '/eligibility',
    eligibilityResult: '/eligibility/result',
    company: '/company/:id',
    companyForm: '/company/:id/edit',
    events: '/events',
    eventsSearch: '/events/search',
    eventDetails: '/events/:id',
    eventAdd: '/events/add',
    eventEdit: '/events/:id/edit',
    jobs: '/jobs',
    jobDetails: '/jobs/:id',
    jobAdd: '/jobs/add',
    jobEdit: '/jobs/:id/edit',
    messaging: '/messaging/:id',
    notifications: '/notifications',
    invite: '/invite/:firstname/:lastname',
    profile: '/profile',
    settings: '/settings',
    sponsor: '/sponsor/:id',
    // club: '/club',
    faq: '/faq',
    faqCategory: '/faq/category/:id',
    faqQuestion: '/faq/:id',
    waiting: '/matching',
    waitingsponsor: '/sponsor/matching',
    sponsormatched: '/sponsor/matched',
    preMatching: '/prematching',
    youngprofile: '/young/:id',
    followedYoungs: '/followed-youngs',
    report: '/report/:id',
    documents: '/documents',
    signinHonor: '/signin/honor',
    verification: '/verification',
    services: '/services',
    serviceDetail: '/services/:id',
  },
  unauthenticated: {
    emailVerification: '/email-verification',
    forgotPassword: '/forgot-password',
    forgotPasswordVerification: '/forgot-password-verification',
    inscription: '/inscription',
    referentInvitation: '/referent-invitation',
    resetPassword: '/reset-password',
    resetPasswordConfirmation: '/reset-password-confirmation',
    register: '/register',
    registerSummary: '/register/summary',
    registerSummaryPE: '/register/summary-pe',
    signin: '/signin',
    welcome: '/welcome',
    welcomeBack: '/welcome-back',
    referentConfirmation: '/referent/confirmation',
    publicEventDetails: '/public-events/:id',
  },
}

export default routes
