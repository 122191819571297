import { BrowserRouter as Router } from 'react-router-dom'

import RoutesApp from '~pages'
import { UpdatePasswordPopin } from '~pages/Authenticated/Home/components'

function App() {
  return (
    <Router>
      <UpdatePasswordPopin />
      <RoutesApp />
    </Router>
  )
}

export default App
