import { Link, generatePath } from 'react-router-dom'
import { Tab, TabPanel, Tabs } from 'react-tabs'

import {
  AttendeeCard,
  ErrorBox,
  FavoriteButton,
  ShareButton,
} from '~components'
import { routes } from '~config'
import { useAuth } from '~context/AuthContext'
import { useToast } from '~context/ToastContext'
import {
  Box,
  Button,
  Grid,
  Header2,
  Loader,
  MarkdownRender,
  SmallText,
  Text,
  TinyText,
} from '~design-system'
import {
  formatIsoTo,
  isDayBeforeNow,
  isReferent,
  isSameIsoDay,
  isSponsor,
  isViewableBySponsor,
  isViewableByYoung,
  isYoung,
} from '~helpers'
import { useCompany, useEvents, useEventsEnum } from '~hooks'

import { SidebarItem, SpeakerCard } from './components'
import {
  EventImage,
  EventSidebar,
  SidebarContainer,
  TabsContainer,
} from './styles'

const placeholder = '/images/event_placeholder.png'
const layoutBreakpoint = 1100

const EventDetail = ({ event }: { event: NQTEvent }) => {
  const {
    authState: { user },
  } = useAuth()
  const { addToast } = useToast()
  const { useAddEventToFavorites, useRemoveEventFromFavorites } = useEvents()
  const { mutate: addToFavorites, isLoading: isAdding } =
    useAddEventToFavorites({ id: event.id, queryKey: ['event', event.id] })
  const { mutate: removeFromFavorites, isLoading: isRemoving } =
    useRemoveEventFromFavorites({ id: event.id, queryKey: ['event', event.id] })
  const { useGetCompanySponsors } = useCompany()
  const { data: companySponsors } = useGetCompanySponsors(user)
  const { useRegisterEvent, useUnregisterEvent, useGetEventAttendees } =
    useEvents()
  const { types } = useEventsEnum()
  const { mutate: register, isLoading } = useRegisterEvent(event.id)
  const { data: attendees } = useGetEventAttendees(event.id)

  const { mutate: unregister, isLoading: isFetching } = useUnregisterEvent(
    event.id
  )
  const isUserRegistered = event.isUserRegistered
  const isEventFull = event.isFull

  // Register/Unregister to the Event
  const handleParticipate = () => {
    isUserRegistered ? unregister({ eventID: event.id }) : register(event.id)
  }

  const handleFavorite = () => {
    event.isFavorite ? removeFromFavorites() : addToFavorites()
  }

  // Conditions to Show Share Button
  const showShareButton = () => {
    // Sponsor OR young with matches = CAN SHARE
    if (
      !isReferent(user) &&
      !!user?.matchs?.length &&
      event.status === 'validate'
    ) {
      return (
        (isSponsor(user) && isViewableByYoung(event)) ||
        (isYoung(user) && isViewableBySponsor(event))
      )
    }
    // Referent With Company Sponsors = CAN SHARE
    else if (
      isReferent(user) &&
      !!companySponsors?.length &&
      event.status === 'validate'
    ) {
      // No chance it would occure, but we never know
      return isViewableBySponsor(event)
    } else {
      return false
    }
  }

  const renderButtonContent = () => {
    if (isLoading || isFetching)
      return (
        <Loader
          color={event.isUserRegistered ? 'blueDark' : 'white'}
          size="small"
        />
      )
    else if (event.isUserRegistered) return 'Annuler ma participation'
    else if (isEventFull) return 'Complet'

    return 'Participer'
  }

  return (
    <>
      {event.status === 'to_validate' && (
        <ErrorBox error="Votre événement est en cours d'approbation" />
      )}
      <Box di="f" ai="center" jc="sb" mb={8}>
        <Header2 as="h1">{event.name}</Header2>
        {user && showShareButton() && (
          <ShareButton
            type="event"
            idToShare={event.id.toString()}
            user={user}
            companySponsors={showShareButton() ? companySponsors : undefined}
          />
        )}
        {user && showShareButton() && (
          <FavoriteButton
            isFavorite={event.isFavorite}
            withText={false}
            onClick={handleFavorite}
            disabled={isAdding || isRemoving}
          />
        )}
        {event.canEdit && (
          <Button
            forceOneLine
            variant="secondary"
            as={Link}
            to={generatePath(routes.authenticated.eventEdit, {
              id: event.id.toString(),
            })}
          >
            Editer cet événement
          </Button>
        )}
      </Box>
      <Grid
        columnGap={10}
        gridAutoRows="1fr"
        templateColums="1fr 320px"
        columnBreakpoint={layoutBreakpoint}
      >
        <Box>
          <EventImage
            src={event.image || placeholder}
            alt="illustration de l'évènement"
          />
          <Tabs>
            <TabsContainer>
              <Tab>Description</Tab>
              {event.speakers && event.speakers.length > 0 && (
                <Tab>Intervenants</Tab>
              )}
              {isReferent(user) && <Tab>Participants</Tab>}
            </TabsContainer>
            <TabPanel>
              {event.description && (
                <MarkdownRender>{event.description}</MarkdownRender>
              )}
            </TabPanel>
            {event.speakers && event.speakers.length > 0 && (
              <TabPanel>
                <Grid
                  templateColums="repeat(auto-fill, minmax(345px, 1fr))"
                  gridAutoRows="1fr"
                >
                  {event.speakers.map((sp) => (
                    <SpeakerCard speaker={sp} />
                  ))}
                </Grid>
              </TabPanel>
            )}
            {isReferent(user) && (
              <TabPanel>
                {attendees && attendees.length > 0 ? (
                  attendees
                    ?.sort(
                      (a, b) => a.fullName?.localeCompare(b.fullName || '') || 1
                    )
                    .map((attendee) => {
                      return (
                        <AttendeeCard attendee={attendee} eventID={event.id} />
                      )
                    })
                ) : (
                  <Text textColor="greyDarkest">
                    Aucun participant pour le moment.
                  </Text>
                )}
              </TabPanel>
            )}
          </Tabs>
        </Box>
        <SidebarContainer columnBreakpoint={layoutBreakpoint}>
          <EventSidebar>
            <Text ml={6} mr={6} pt={6} mb={4} weight="medium">
              Informations
            </Text>
            {event.venue && (
              <SidebarItem icon="poi" title={event.venue}>
                {event.address && `${event.address.properties.label}`}
              </SidebarItem>
            )}
            <SidebarItem
              icon="calendar"
              title={formatIsoTo(event.dateStart, 'dd MMMM yyyy')}
            >
              Commence à {formatIsoTo(event.dateStart, 'HH:mm')}
              {event.dateEnd &&
                ` et se termine à 
                 ${formatIsoTo(event.dateEnd, 'HH:mm')}
                 ${
                   !isSameIsoDay(event.dateStart, event.dateEnd)
                     ? ` le ${formatIsoTo(event.dateEnd, 'dd MMMM yyyy')}`
                     : ''
                 }`}
              .
            </SidebarItem>
            {types && (
              <SidebarItem icon="building" title="Salon">
                {types[event.type]}
              </SidebarItem>
            )}
            {!!event.currentAttendees && (
              <SidebarItem icon="team" title="Nombre de participants">
                {event.currentAttendees} participants
              </SidebarItem>
            )}
            {!!event.promoter?.length && (
              <SidebarItem icon="avatar" title="Organisateur(s) de l'évènement">
                {event.promoter?.map((promoter, index) => (
                  <SmallText textColor="greyDarkest" key={`promoter-${index}`}>
                    {promoter}
                  </SmallText>
                ))}
              </SidebarItem>
            )}

            {event.ticketLink && (
              <SidebarItem icon="devices" title="Lien d'inscription">
                <a
                  href={event.ticketLink}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {event.ticketLink}
                </a>
              </SidebarItem>
            )}

            <SidebarItem icon="quote" title="Statut">
              <TinyText textColor="orange">
                {isDayBeforeNow(event.dateStart) ? 'Passé' : 'À venir'}
              </TinyText>
            </SidebarItem>

            {isUserRegistered && event.eventLink && event.type !== 'physics' && (
              <SidebarItem icon="devices" title="Lien vers l'évènement">
                <a
                  href={event.eventLink}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {event.eventLink}
                </a>
              </SidebarItem>
            )}
          </EventSidebar>

          <Button
            mt={4}
            fullWidth
            option="outlined"
            as="a"
            rel="noreferrer noopener"
            target="_blank"
            download
            role="button"
            title="Télécharger le ficher .ics"
            href={event.icalLink}
            onClick={() =>
              addToast({
                title: 'Le fichier a bien été téléchargé',
                type: 'success',
              })
            }
          >
            Ajouter à mon calendrier
          </Button>

          <Button
            disabled={!isUserRegistered && isEventFull}
            mt={4}
            fullWidth
            onClick={handleParticipate}
            option={isUserRegistered ? 'outlined' : undefined}
          >
            {renderButtonContent()}
          </Button>
        </SidebarContainer>
      </Grid>
    </>
  )
}

export default EventDetail
