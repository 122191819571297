// Check if the user is a 'Sponsor'
const isSponsor = (user?: User | UserTeaser) => {
  const roleStored = localStorage.getItem('currentRole')
  return (
    user && user.role?.includes('parrain') && roleStored !== 'company-referent'
  )
}

// Check if the user is a 'Company Referent'
const isReferent = (user?: User | UserTeaser) => {
  const roleStored = localStorage.getItem('currentRole')
  return (
    user && user.role?.includes('company-referent') && roleStored !== 'parrain'
  )
}

// Check if the user is a 'Sponsor' and 'Company Referent'
const isSponsorReferent = (user?: User | UserTeaser) =>
  user &&
  user.role?.includes('parrain') &&
  user.role?.includes('company-referent')

const getCurrentRole = (user?: User | UserTeaser) => {
  if (user) {
    return isSponsorReferent(user)
      ? (localStorage.getItem('currentRole') as LocalRole)
      : undefined
  }
  return undefined
}

// Check if the user is a 'Young'
const isYoung = (user?: User | UserTeaser) =>
  user && user.role?.includes('jeune')

const canLogIn = (user?: User | UserTeaser) =>
  user &&
  (user.role?.includes('jeune') ||
    user.role?.includes('parrain') ||
    user.role?.includes('company-referent'))

// Transform Civlity Slug
const getFrenchCivility = (slug: 'Mr.' | 'Mrs.') =>
  slug === 'Mrs.' ? 'Mme.' : slug

// Check if User Onboarding is Over
const isOnboarded = (user?: User) => user && user.onboardingNeeded === 'false'

export {
  isSponsor,
  isYoung,
  isReferent,
  isSponsorReferent,
  getFrenchCivility,
  isOnboarded,
  canLogIn,
  getCurrentRole,
}
