declare global {
  interface Window {
    // eslint-disable-next-line
    env: any
  }
}

const checkEnv = (env: any) => {
  for (const key in env) {
    if (env[key] === undefined) {
      console.error(`ENV var ${key} should be set (found 'undefined')`)
      // throw new Error(`ENV var ${key} should be set (found 'undefined')`)
    }
  }
}

const windowEnv = typeof window !== 'undefined' ? window.env : {}

const processEnv = {
  API_URL: process.env.REACT_APP_API_URL,
  LEVELUP_URL: process.env.REACT_APP_LEVELUP_URL,
  PUSHER_KEY: process.env.REACT_APP_PUSHER_KEY,
  PUSHER_CLUSTER: process.env.REACT_APP_PUSHER_CLUSTER,
  APP_ENV: process.env.NODE_ENV,
  MESSAGING_SOCKET_URL: process.env.REACT_APP_MESSAGING_SOCKET_URL || '1',
  VERSION: process.env.REACT_APP_VERSION || 1,
  // Ok to let the `SENTRY_DSN` from devOps team: public URL
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  SENTRY_ENV: process.env.REACT_APP_SENTRY_ENV,
  LOGROCKET_URL: process.env.REACT_APP_LOGROCKET_URL,
  IS_DEV: process.env.NODE_ENV === 'development',
  MAINTENANCE_MODE: process.env.REACT_APP_MAINTENANCE_MODE || false,
}

const env = {
  ...processEnv,
  ...windowEnv,
}

if (!process.env.IS_CI) {
  checkEnv(env)
}

export default env
