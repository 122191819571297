import { isEmpty } from 'lodash'
import { useMemo, useRef, useState } from 'react'
import Slider from 'react-slick'

import CardInfo from '~components/CardInformation'
import { routes } from '~config'
import { Icon } from '~design-system'
import { isReferent, isSponsor, isYoung } from '~helpers'
import ChatCard from '~pages/Authenticated/Home/components/ChatCard'
import WelcomeCard from '~pages/Authenticated/Home/components/WelcomeCard'

import { CardsPager } from '../styles'

type Props = {
  user: User
}

const Cards = ({ user }: Props) => {
  const [activeItem, setActiveItem] = useState<number>(1)

  const sliderRef = useRef<Slider>(null)

  const isProfileCompleted = useMemo(() => {
    return (
      user &&
      isYoung(user) &&
      (!user.skills.length ||
        !user.hobbies.length ||
        (user.resumes && !user.resumes[0]?.url) ||
        !user.phone)
    )
  }, [user])

  const cards = useMemo(() => {
    const cardsArray: React.ReactNode[] = []

    if (isYoung(user) && isEmpty(user.matchs)) {
      cardsArray?.push(<WelcomeCard user={user} />)
    }
    const chatCardContent = () => {
      if (isSponsor(user) || isReferent(user))
        return 'Nous avons trouvé votre jeune, discutez avec lui dès maintenant'
      return 'Nous avons trouvé ton mentor, discuter avec lui dès maintenant'
    }

    if (!isEmpty(user.matchs)) {
      cardsArray?.push(
        <ChatCard
          key="chat-with-mentor"
          content={chatCardContent()}
          illustration={<img alt="" src="/images/messages.svg" />}
        />
      )
    }

    if (isProfileCompleted) {
      cardsArray?.push(
        <CardInfo
          key="complete-profile"
          route={routes.authenticated.profile}
          content="Viens compléter les informations dans ton profil"
          actionMessage="Aller sur mon profil"
          illustration={<img alt="coucou" src="/images/not-eligible.svg" />}
        />
      )
    }

    if (user && isYoung(user) && !user.desiredProfessions.length) {
      cardsArray?.push(
        <CardInfo
          key="complete-jobs"
          route={routes.authenticated.profile}
          content="Viens indiquer les métiers que tu recherches dans ton profil"
          actionMessage="Compléter mes métiers"
          illustration={<img alt="coucou" src="images/not-eligible.svg" />}
        />
      )
    }

    if (user && isYoung(user) && !user.sector) {
      cardsArray?.push(
        <CardInfo
          key="complete-sector"
          route={routes.authenticated.profile}
          content="Viens indiquer les secteurs et sous-secteurs que tu recherches dans ton profil"
          actionMessage="Compléter mes secteurs"
          illustration={<img alt="coucou" src="/images/not-eligible.svg" />}
        />
      )
    }

    // Meteo for matchs
    user.matchs?.forEach((match) => {
      if (match.canAddFeeling) {
        cardsArray?.push(
          <CardInfo
            key={'complete-feeling'}
            upperMessage="Disponible"
            route={`weather/${match.matchingId}`}
            content={
              isSponsor(user)
                ? `Vous pouvez noter ${match.fullName} sur les ${match.feelingWeekFrequency} dernières semaines`
                : `Tu peux noter ${match.fullName} sur les ${match.feelingWeekFrequency} dernières semaines`
            }
            actionMessage={
              isSponsor(user) ? 'Noter votre filleul' : 'Noter votre parrain'
            }
            illustration={<img alt="" src="/images/weather.svg" />}
          />
        )
      }
    })

    return cardsArray
  }, [isProfileCompleted, user])

  return (
    <>
      {cards.length > 1 && (
        <CardsPager>
          <button
            onClick={() => sliderRef.current?.slickPrev()}
            title="Revenir à la slide précédente"
          >
            <Icon name="arrow-left" color="primary" />
          </button>
          <p>
            {activeItem} / <span>{cards.length}</span>
          </p>
          <button
            onClick={() => sliderRef.current?.slickNext()}
            title="Aller à la slide suivante"
          >
            <Icon name="arrow-right" color="primary" />
          </button>
        </CardsPager>
      )}
      <Slider
        ref={sliderRef}
        dots={false}
        slidesToShow={1}
        arrows={false}
        infinite={true}
        afterChange={(index) => {
          setActiveItem(index + 1)
        }}
        swipe
      >
        {cards}
      </Slider>
    </>
  )
}

export default Cards
