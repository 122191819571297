import * as Sentry from '@sentry/react'
import LogRocket from 'logrocket'
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'

import { queryClient } from '~api'
import AuthService from '~api/auth'
import { GlobalLoader } from '~components'
import { useGetEnums, useUser } from '~hooks'
import getPusher from '~services/pusher'

type AuthInfosProps = {
  token: string | null
  user?: User
  localRole?: 'parrain' | 'company-referent'
}

type AuthContextState = {
  authState: AuthInfosProps
  isAuthenticated: () => boolean
  logout: () => void
  setAuthState: ({ token, user, localRole }: AuthInfosProps) => void
}

const AuthContext = createContext({} as AuthContextState)

type Props = {
  children: ReactNode
}

type LocalRole = 'parrain' | 'company-referent' | undefined

const AuthProvider = ({ children }: Props) => {
  // since routes are not instantiated, we cant use router functions
  var searchParams = new URLSearchParams(global.location.search)
  const token = localStorage.getItem('token') || searchParams.get('accessToken')
  const localRole = localStorage.getItem('currentRole') as LocalRole
  const { useGetUser } = useUser()
  const { isLoading, data } = useGetUser(token)
  const { isLoading: isLoadingEnums } = useGetEnums()

  const [authState, setAuthState] = useState<AuthInfosProps>({
    token,
    user: data?.user,
    localRole,
  })

  // Set token in localstorage if set via url
  useEffect(() => {
    token && localStorage.setItem('token', token)
  }, [token])

  useEffect(() => {
    if (data?.user.id) {
      LogRocket.identify(`${data.user.id}`)
      Sentry.setUser({ id: `${data.user.id}` })
      const pusher = getPusher()
      const channel = pusher.subscribe(`private-user.${data.user.id}`)
      channel.bind(
        'Illuminate\\Notifications\\Events\\BroadcastNotificationCreated',
        () => {
          queryClient.setQueryData(['unread-notifications'], {
            hasUnreadNotifications: true,
          })
          queryClient.invalidateQueries(['notifications'])
        }
      )

      return () => {
        pusher.unsubscribe(`user.${data.user.id}`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.user.id])

  const setAuthInfos = ({ token, user, localRole }: AuthInfosProps) => {
    if (token) {
      localStorage.setItem('token', token)
    } else {
      localStorage.removeItem('token')
    }
    localRole && localStorage.setItem('currentRole', localRole)
    queryClient.invalidateQueries('user')

    setAuthState({
      ...authState,
      token,
      user,
      localRole,
    })
  }

  const logout = async () => {
    const hasSeenWelcomeTour = localStorage.getItem('hasSeenWelcomeTour')

    await AuthService.logout()
    localStorage.clear()
    // @ts-ignore
    localStorage.setItem('hasSeenWelcomeTour', hasSeenWelcomeTour)
    queryClient.clear()
    setAuthState({
      token: null,
      user: undefined,
      localRole: undefined,
    })
  }

  const isAuthenticated = () => {
    return (data && !!data?.user) || false
  }

  if (isLoading || isLoadingEnums) {
    return <GlobalLoader />
  }

  return (
    <AuthContext.Provider
      value={{
        authState: {
          token,
          user: data?.user,
          localRole: localRole,
        },
        isAuthenticated,
        logout,
        setAuthState: (authInfo) => setAuthInfos(authInfo),
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const useAuth = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }

  return context
}

export { AuthProvider, useAuth }
