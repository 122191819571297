import { Link } from 'react-router-dom'

import { Layout } from '~components'
import { Button, PopIn, Text } from '~design-system'

const Unauthorized = () => (
  <Layout.Informations>
    <PopIn open>
      <PopIn.Picture>
        <img src="/images/protection.svg" alt="" />
      </PopIn.Picture>
      <PopIn.Content>
        <Text weight="medium">
          Vous n'avez pas les droits pour accéder à cette page... 😔
        </Text>
      </PopIn.Content>
      <PopIn.Actions
        actions={[
          <Button as={Link} to="/">
            Aller à l'accueil
          </Button>,
        ]}
      />
    </PopIn>
  </Layout.Informations>
)

export default Unauthorized
