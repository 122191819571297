import { ReactNode } from 'react'

import Icon from '~design-system/Icon'

import Box from '../Box'
import { Container, MessageText } from './styles'

interface Props {
  children: Message['body'] | ReactNode
  variant?: 'answer' | 'question'
  attachment?: Message['attachment']
}

const Message = ({ children, variant = 'question', attachment }: Props) => {
  const isAnswer = variant === 'answer'

  return (
    <Box
      di="f"
      ai="flex-start"
      fg={1}
      jc={isAnswer ? 'end' : 'start'}
      fd={isAnswer ? 'row-reverse' : 'row'}
    >
      <Container variant={variant}>
        {typeof children === 'string' ? (
          <MessageText isAnswer={isAnswer}>{children}</MessageText>
        ) : (
          children
        )}
      </Container>
      {attachment && (
        <Container variant={variant} isAttachment>
          <a href={attachment.url} target="_blank" download rel="noreferrer">
            <Icon
              name="download"
              size="small"
              color={isAnswer ? 'white' : 'black'}
            />
          </a>
        </Container>
      )}
    </Box>
  )
}

export default Message
