import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { PreMatchingService } from '~api'

import queryClient from '../api/queryClient'

function usePreMatching() {
  const navigate = useNavigate()
  const useGetPreMatchings = (isEnabled: boolean) =>
    useQuery(['preMatchings'], () => PreMatchingService.apiGetPreMatchings(), {
      enabled: isEnabled,
    })

  const useConfirmPreMatching = () =>
    useMutation(PreMatchingService.apiPostConfirmPreMatching, {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries('thread-' + data.data.threadId)
        await queryClient.invalidateQueries('threads')
        await queryClient.invalidateQueries('user')
        navigate(`/messaging/${data.data.threadId}`)
      },
    })

  return {
    useGetPreMatchings,
    useConfirmPreMatching,
  }
}

export default usePreMatching
